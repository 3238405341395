import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['priceField', 'quantityField', 'discountField']

  clearStockAndDiscountFields() {
    if (this.stockItemFieldsPresent()) {
      this.priceFieldTarget.value = ''
      this.quantityFieldTarget.value = ''
      if (this.hasDiscountFieldTarget) {
        this.discountFieldTarget.value = ''
      }
    }
  }

  stockItemFieldsPresent() {
    return this.hasPriceFieldTarget && this.hasQuantityFieldTarget
  }
}
