import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    $(this.element).sortable({
      items: ".filter-row:not(.unsortable)",
      stop: function () {
        document.dispatchEvent(
          new CustomEvent("sortable-stopped"),
        );
      },
    });
  }
}
