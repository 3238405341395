import consumer from './consumer'

window.openCsvImportConnection = function () {
  consumer.subscriptions.create(
    { channel: 'CsvImportChannel' },
    {
      connected: function () {
        // Called when the subscription has been initiated by the server
      },

      disconnected: function () {
        // Called when the subscription has been terminated by the server
      },

      received: function (data) {
        $('#csv_imports_index_table').bootgrid('reload')
        if (data['has_errors']) {
          notify(
            '',
            'danger',
            'Error parsing data from your file. Click here to check your imports',
            null,
            data['link']
          )
          data['errors'].map((error) => notify('', 'danger', error, null, data['link']))
        } else {
          notify("Your import it's completed. Click here to view your imports", 'success', 'Success! ', null, data['link'])
        }
      },
    }
  )
}
