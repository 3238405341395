import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["quantity", "price", "debit", "credit"];

  getQuantity = () => {
    return this.hasQuantityTarget
      ? parseFloat(this.quantityTarget.value)
      : null;
  };

  getPrice = () => {
    return this.hasPriceTarget ? parseFloat(this.priceTarget.value) : null;
  };

  setQuantity = (n) => (this.quantityTarget.value = n.toFixed(3));
  setPrice = (n) => (this.priceTarget.value = n.toFixed(3));
  setDebit = (n) => (this.debitTarget.value = n.toFixed(2));

  updateFromQuantity() {
    if (this.isNull(this.getQuantity())) {
      this.quantityTarget.value = null;
      this.priceTarget.value = null;
    } else if (!this.isNull(this.getDebitOrCreditValue())) {
      this.updatePriceFromQuantityAndDebitOrCredit();
    } else if (!this.isNull(this.getPrice())) {
      this.updateDebitCreditFromQuantityAndPrice();
    }
  }

  updateFromPrice() {
    if (this.isNull(this.getPrice())) {
      this.priceTarget.value = null;
      this.quantityTarget.value = null;
    } else if (!this.isNull(this.getDebitOrCreditValue())) {
      this.updateQuantityFromPriceAndDebitOrCredit();
    } else if (!this.isNull(this.getQuantity())) {
      this.updateDebitCreditFromQuantityAndPrice();
    }
  }

  updateFromDebitOrCredit() {
    if (this.isNull(this.getDebitOrCreditValue())) {
      this.quantityTarget.value = null;
      this.priceTarget.value = null;
    } else if (!this.isNull(this.getQuantity())) {
      this.updatePriceFromQuantityAndDebitOrCredit();
    } else if (!this.isNull(this.getPrice())) {
      this.updateQuantityFromPriceAndDebitOrCredit();
    }
  }

  updatePriceFromQuantityAndDebitOrCredit() {
    if (this.getQuantity() !== 0) {
      this.setPrice(this.getDebitOrCreditValue() / this.getQuantity());
    } else {
      this.setPrice(0);
    }
  }

  updateQuantityFromPriceAndDebitOrCredit() {
    if (this.getPrice() !== 0) {
      this.setQuantity(this.getDebitOrCreditValue() / this.getPrice());
    } else {
      this.setQuantity(0);
    }
  }

  updateDebitCreditFromQuantityAndPrice() {
    if (this.creditTarget.value) {
      this.setCredit(this.getQuantity() * this.getPrice());
    } else {
      this.setDebit(this.getQuantity() * this.getPrice());
    }
    this.submitFieldEvents();
  }

  getDebitOrCreditValue() {
    if (this.debitTarget.value) {
      return this.debitTarget.value;
    } else {
      return this.creditTarget.value;
    }
  }

  isNull(value) {
    return !value && value !== 0;
  }

  submitFieldEvents() {
    this.debitTarget.dispatchEvent(new Event("change"));
    this.creditTarget.dispatchEvent(new Event("change"));
  }
}
