/**
 * Rounds a numeric value to two decimal places
 * @param {number} value - The value to round
 * @returns {number} - The rounded value
 */
export function roundValue(value) {
  return 1 * value.toFixed(2);
}

/**
 * Checks if a numeric value is zero, considering a precision of two decimal places
 * @param {number} value - The value to check
 * @returns {boolean} - True if the value is zero, false otherwise
 */
export function isZero(value) {
  return value.toFixed(2) == 0.0;
}

/**
 * Checks if a value is null or undefined
 * @param {any} value - The value to check
 * @returns {boolean} - True if the value is null or undefined, false otherwise
 */
export function isNull(value) {
  return !value && value !== 0;
}

/**
 * Sanitizes a string amount, returning the
 * numeric value without any currency symbol
 * or delimiters while preserving negative amounts
 * @param {string} amount - The string to sanitize
 * @returns {number} - The sanitized amount
 */
export function sanitizeAmount(amount) {
  return Number(amount.replace(/[^0-9.-]+/g, ""));
}
