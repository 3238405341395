import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "button", "loadButton", "index", "checkbox" ]

  toggle() {
    this.updateIds()
    this.toggleDisable()
    this.updateHref()
  };

  updateIds() {
    let uploadIds = []
    
    this.checkboxTargets.forEach(function(checkbox) {
      if (checkbox.checked) {
        uploadIds.push(checkbox.dataset.uploadId)
      }
    })
    this.setUploadIds(uploadIds)
  };

  toggleDisable() {
    if (this.uploadIds().length) {
      this.buttonTarget.classList.remove('disabled')
    } else {
      this.buttonTarget.classList.add('disabled')
    };
  };

  updateHref() {
    const url = Routes.bulk_destroy_uploads_path({ format: 'js', upload_ids: this.uploadIds() })
    this.buttonTarget.setAttribute('href', url)
  };

  nextPageHref() {
    this.setPage(parseInt(this.page()) + 1)
    this.loadButtonTarget.setAttribute('href', Routes.uploads_path({ page: this.page() }))
  };

  page() {
    return this.indexTarget.dataset.page
  }

  uploadIds() {
    return JSON.parse(this.indexTarget.dataset.uploadIds)
  }

  setPage(value) {
    this.indexTarget.setAttribute('data-page', value)
  }

  setUploadIds(value) {
    this.indexTarget.setAttribute('data-upload-ids', JSON.stringify(value))
  }
}