import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "textField" ]
  static values = { 
    regex: String,
    flag: String, 
    triggered: Boolean
  }

  checkRegex() {
    if (this.triggeredValue) { return; }
    if (this.textFieldTarget.value.match(new RegExp(this.regexValue, this.flagValue))) {
      this.triggeredValue = true
      this.textFieldTarget.dispatchEvent(new Event('regex-match'))
    }
  }
}