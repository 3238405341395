import { Controller } from "stimulus";
import { sanitizeAmount } from "./helpers/_calculations";
import { confirmationModal } from "./helpers/_modals";

export default class BankReconciliationController extends Controller {
  static targets = [
    "selectAllCheckbox",
    "saveAllButton",
    "backButton",
    "filterSelectAllCheckbox",
    "filterSelectZeroCheckbox",
  ];

  initialize() {
    $("#modal-lg").on("shown.bs.modal", this.handleOpeningModal.bind(this));
    $("#modal-lg").on("hide.bs.modal", this.handleClosingModal.bind(this));

    this.initializeVariables();
  }

  reInitialize() {
    this.initializeVariables();
    this.reloadCurrentBootgrid();
  }

  initializeVariables() {
    this.isBankReconciliationOpened = false;
    this.reconciledTotal = 0;
    this.initialReconciledTotal = 0;
    this.isBankReconciliationInitialized = false;
    this.isBankReconciliationOpened = false;
    this.matchedTransactionsToSave = [];
  }

  setInitialStatus() {
    this.insertElements([
      {
        selector: "#modal-lg .actionBar .actions",
        position: "beforeend",
        template: this.bankReconciliationButtonTemplate(),
      },
      {
        selector: "#modal-lg .pagination",
        position: "afterend",
        template: this.backButtonTemplate(),
      },
      {
        selector: "#modal-lg .pagination",
        position: "afterend",
        template: this.saveAllButtonTemplate(),
      },
    ]);
  }

  setBootGridEventListeners() {
    const bootgrid = $("#account_table").bootgrid();
    bootgrid.on(
      "loaded.rs.jquery.bootgrid",
      this.handleBootGridLoaded.bind(this),
    );
    bootgrid.on(
      "load.rs.jquery.bootgrid",
      this.handleBootGridWillLoad.bind(this),
    );
  }

  setInitialReconciledAmounts() {
    this.updateElements("#modal-lg .checkbox .form-control", (el) => {
      el.checked ? this.updateMatchedAmount(el) : this.setZeroAmount(el);
    });
  }

  formatModalTableHeader() {
    this.updateElement(
      "[data-column-id='matched_to_statement']",
      '<span>Matched<br/>to Statement <input type="checkbox" data-bank-reconciliation-target="selectAllCheckbox" data-action="click->bank-reconciliation#handleSelectAllClicked"/></span>',
    );
    this.updateElement(
      "[data-column-id='transaction_on_statement']",
      '<span>Transaction<br/>on Statement</span><button class="btn btn-xs btn-primary" data-action="click->bank-reconciliation#handleFilterClicked"><i class="zmdi zmdi-caret-down zmdi-hc-fw"></i></button>' +
        '<div id="bank-reconciliation-filter" class="hidden" role="menu"><ul><li><div>Select All</div><input type="checkbox" data-bank-reconciliation-target="filterSelectAllCheckbox" data-action="click->bank-reconciliation#handleFilterSelectAllClicked"/></li>' +
        '<li><div>Unreconciled</div><input type="checkbox" data-bank-reconciliation-target="filterSelectZeroCheckbox" data-action="click->bank-reconciliation#handleFilterSelectZeroClicked"/></li></ul></div>',
    );
  }

  insertElements(elements) {
    elements.forEach(({ selector, position, template }) => {
      this.insertElement(selector, position, template);
    });
  }

  insertElement(selector, position, template) {
    document.querySelector(selector).insertAdjacentHTML(position, template);
  }

  bankReconciliationButtonTemplate() {
    return '<button class="btn btn-icon bgm-blue" type="button" title="Bank Reconciliation" data-action="click->bank-reconciliation#handleBankReconciliationButtonClick"><span class="zmdi icon zmdi-local-convenience-store"></span></button>';
  }

  saveAllButtonTemplate() {
    return '<span class="ml-8 hidden"><button class="btn btn-primary waves-effect disabled" data-bank-reconciliation-target="saveAllButton" data-action="click->bank-reconciliation#handleSaveAllClick"><i class="zmdi zmdi-check-all zmdi-hc-fw"></i> Save All</button></span>';
  }

  backButtonTemplate() {
    return '<span class="ml-8 hidden"><button class="btn btn-danger waves-effect" data-bank-reconciliation-target="backButton" data-action="click->bank-reconciliation#handleBackClick"><i class="zmdi zmdi-check-all zmdi-hc-fw"></i> Back</button></span>';
  }

  toggleElementsVisibility() {
    // Some columns are required to be hidden when seeing the reconciliation ones
    // but jQuery bootgrid API reloads data when removing columns, so we are doing it ourselves...
    this.setElementsVisibility([
      ".bank-reconciliation",
      "[data-column-id='quantity']",
      ".quantity",
      "[data-column-id='enterprise']",
      ".enterprise",
      "[data-column-id='price']",
      ".price",
    ]);
    this.saveAllButtonTarget.parentNode.classList.toggle("hidden");
    this.backButtonTarget.parentNode.classList.toggle("hidden");
    this.updateElement("tfoot .bank-reconciliation", "Reconciled Total:");
  }

  modifyOrPushMatchedTransactionToSave(id, newStatus, accountId) {
    let found = false;
    for (let item of this.matchedTransactionsToSave) {
      if (item.id === id) {
        item.matched_to_statement = newStatus;
        found = true;
        break;
      }
    }
    if (!found) {
      this.matchedTransactionsToSave.push({
        id: id,
        matched_to_statement: newStatus,
        account_id: accountId
      });
    }
  }

  hasMatchedTransactionsToSave() {
    return this.matchedTransactionsToSave.length > 0;
  }

  match(event) {
    const el = event.target;
    const parentNode = el.closest("tr");
    const onStatementElement = parentNode.querySelector(".on-statement");
    this.modifyOrPushMatchedTransactionToSave(el.id, el.checked, el.dataset.accountId);
    onStatementElement.innerHTML = this.getAmount(el);

    if (this.hasSelectAllTarget) this.selectAllCheckboxTarget.checked = false;

    const amount = parseFloat(sanitizeAmount(this.getAmount(el)));

    if (event.target.checked) {
      this.reconciledTotal += amount;
    } else {
      this.reconciledTotal -= amount;
      onStatementElement.innerHTML = "0.00";
    }

    this.selectAllCheckboxTarget.checked = false;
    this.filterSelectAllCheckboxTarget.checked = false;
    this.filterSelectZeroCheckboxTarget.checked = false;
    this.updateReconciledTotal();
    this.setSaveAllButtonStatus();
  }

  setSaveAllButtonStatus() {
    this.saveAllButtonTarget.classList.toggle(
      "disabled",
      !this.hasMatchedTransactionsToSave(),
    );
  }

  getAmount(element) {
    const parentNode = element.closest("tr");
    const creditAmount = parentNode
      .querySelector(".credit-amount")
      .innerHTML.trim();
    const debitAmount = parentNode
      .querySelector(".debit-amount")
      .innerHTML.trim();

    return creditAmount !== "&nbsp;" ? `-${creditAmount}` : debitAmount;
  }

  updateReconciledTotal() {
    const total = this.initialReconciledTotal + this.reconciledTotal;
    this.updateElement("tfoot .on-statement", parseFloat(total).toFixed(2));
  }

  async saveBankReconciliation() {
    try {
      const csrfToken = document.querySelector(
        'meta[name="csrf-token"]',
      ).content;
      const response = await fetch(
        "/bank_reconciliations/update",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
          },
          body: JSON.stringify({
            matched_transactions: this.matchedTransactionsToSave,
          }),
        },
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error);
      } else {
        this.matchedTransactionsToSave = [];
        this.initialReconciledTotal = this.initialReconciledTotal + this.reconciledTotal;
        this.reconciledTotal = 0;
        this.setSaveAllButtonStatus();
        notify(data.message, "success", "Success! ");
      }
    } catch (error) {
      notify(error, "danger", "Error! ");
    }
  }

  updateElements(selector, callback) {
    const elements = Array.from(document.querySelectorAll(selector));
    elements.forEach(callback);
  }

  updateElement(selector, newHTML) {
    if (document.querySelector(selector)) {
      document.querySelector(selector).innerHTML = newHTML;
    }
  }

  setElementsVisibility(selectors, action = "toggle") {
    selectors.forEach((selector) => {
      document.querySelectorAll(selector).forEach((el) => {
        this.updateVisibility(el, action);
      });
    });
  }

  updateVisibility(element, action) {
    switch (action) {
      case "toggle":
        element.classList.toggle("hidden");
        break;
      case "hide":
        element.classList.add("hidden");
        break;
      case "show":
        element.classList.remove("hidden");
        break;
    }
  }

  updateMatchedAmount(el) {
    this.updateAmount(el, ".debit-amount");
    this.updateAmount(el, ".credit-amount", true);
  }

  setZeroAmount(el) {
    this.getClosestElementForSelector(el, ".on-statement").innerHTML = "0.00";
  }

  updateAmount(el, selector, isCredit = false) {
    const rowElement = el.closest("tr");
    const amountField = rowElement.querySelector(selector).innerHTML;

    if (amountField && amountField !== "&nbsp;") {
      const targetElement = this.getClosestElementForSelector(
        el,
        ".on-statement",
      );
      targetElement.innerHTML = isCredit ? `-${amountField}` : amountField;
    }
  }

  getClosestElementForSelector(el, selector) {
    return el.closest("tr").querySelector(selector);
  }

  handleSaveAllClick() {
    if (this.hasMatchedTransactionsToSave()) {
      this.saveBankReconciliation();
    }
  }

  handleBankReconciliationButtonClick() {
    if (!this.isBankReconciliationOpened) {
      this.toggleElementsVisibility();
      this.isBankReconciliationInitialized = true;
      this.isBankReconciliationOpened = true;
      this.formatModalTableHeader();
    }
  }

  handleSelectAllClicked(e) {
    this.filterSelectAllCheckboxTarget.checked = false;
    this.filterSelectZeroCheckboxTarget.checked = false;

    // Make sure all rows are visible and not hidden by any filter
    this.updateTableRowVisibility(
      ".bank-reconciliation .checkbox input",
      false,
    );

    if (e.target.checked) {
      this.deselectAll();
      this.selectAll();
    } else {
      this.deselectAllWithConfirmation(e);
    }
  }

  handleBackClick() {
    if (this.hasMatchedTransactionsToSave()) {
      this.showConfirmationModal(() => {
        this.reInitialize();
      });
    } else {
      this.reInitialize();
    }
  }

  handleOpeningModal() {
    this.setInitialStatus();
    this.setInitialReconciledAmounts();
    this.setInitialReconciledTotal();
    this.updateReconciledTotal();
    this.setBootGridEventListeners();
  }

  handleClosingModal(e) {
    if (!this.hasMatchedTransactionsToSave()) {
      this.reInitialize();
      return;
    }

    e.preventDefault();

    this.showConfirmationModal(() => {
      this.reInitialize();
      $("#modal-lg").modal("hide");
    });
  }

  showConfirmationModal(callback) {
    confirmationModal(
      "Are you sure you want to leave this screen without saving your transactions?",
      () => {
        callback();
      },
    );
  }

  handleBootGridLoaded() {
    if (this.hasMatchedTransactionsToSave()) {
      this.updateMatchedTransactions();
    }

    this.formatModalTableHeader();
    this.setInitialReconciledAmounts();
    this.setInitialReconciledTotal();
    this.updateReconciledTotal();

    if (this.hasSelectAllCheckboxTarget) {
      this.selectAllCheckboxTarget.checked = false;
    }

    if (this.isBankReconciliationOpened) {
      this.toggleElementsVisibility();
    }
  }

  updateMatchedTransactions() {
    let elements = this.element.querySelectorAll("#modal-lg tbody tr input");
    this.matchedTransactionsToSave.forEach((transaction) => {
      elements.forEach((element) => {
        if (element.id === transaction.id) {
          element.checked = transaction.matched_to_statement;
        }
      });
    });
  }

  setInitialReconciledTotal() {
    if (this.element.querySelector("tfoot tr .on-statement")) {
      this.initialReconciledTotal = Number(
        this.element.querySelector("tfoot tr .on-statement").innerHTML,
      );
    }
  }

  handleBootGridWillLoad() {
    this.setElementsVisibility(
      [
        "[data-column-id='quantity']",
        ".quantity",
        "[data-column-id='enterprise']",
        ".enterprise",
        "[data-column-id='price']",
        ".price",
      ],
      "show",
    );
    this.setElementsVisibility([".bank-reconciliation"], "hide");
    this.saveAllButtonTarget.parentNode.classList.add("hidden");
    this.backButtonTarget.parentNode.classList.add("hidden");
    if (this.hasSelectAllTarget) this.selectAllCheckboxTarget.checked = false;
    this.setSaveAllButtonStatus();
  }

  handleFilterClicked() {
    this.toggleFilterMenu();
  }

  selectAll() {
    const elements = document.querySelectorAll(
      ".bank-reconciliation .checkbox input",
    );
    elements.forEach((el) => {
      const parentNode = el.closest("tr");
      if (!parentNode.classList.contains("hidden")) {
        const onStatementElement = parentNode.querySelector(".on-statement");
        onStatementElement.innerHTML = this.getAmount(el);
        const amount = parseFloat(sanitizeAmount(this.getAmount(el)));

        el.checked = true;
        this.reconciledTotal += amount;
        this.modifyOrPushMatchedTransactionToSave(el.id, el.checked, el.dataset.accountId);
        this.updateReconciledTotal();
        this.setSaveAllButtonStatus();
      }
    });
  }

  deselectAllWithConfirmation(el) {
    confirmationModal(
      "Are you sure you want to uncheck all transactions?",
      () => {
        this.deselectAll();
        el.target.checked = false;
      },
    );

    el.target.checked = true;
  }

  deselectAll() {
    const elements = document.querySelectorAll(
      ".bank-reconciliation .checkbox input",
    );
    elements.forEach((el) => {
      const parentNode = el.closest("tr");
      if (!parentNode.classList.contains("hidden")) {
        const onStatementElement = parentNode.querySelector(".on-statement");
        onStatementElement.innerHTML = "0.00";
        const amount = parseFloat(sanitizeAmount(this.getAmount(el)));

        if (el.checked) {
          el.checked = false;
          this.reconciledTotal -= amount;
        }

        this.modifyOrPushMatchedTransactionToSave(el.id, el.checked, el.dataset.accountId);
        this.updateReconciledTotal();
        this.setSaveAllButtonStatus();
      }
    });
  }

  selectAllWithZeroAmount() {
    const elements = document.querySelectorAll(
      ".bank-reconciliation .checkbox input",
    );
    elements.forEach((el) => {
      const parentNode = el.closest("tr");
      const onStatementElement = parentNode.querySelector(".on-statement");
      onStatementElement.innerHTML = "0.00";
      const amount = parseFloat(sanitizeAmount(this.getAmount(el)));

      if (!el.checked) {
        el.checked = true;
        this.reconciledTotal += amount;
      }

      this.modifyOrPushMatchedTransactionToSave(el.id, el.checked, el.dataset.accountId);
      this.updateReconciledTotal();
    });
  }

  handleFilterSelectAllClicked(e) {
    e.target.checked = true;
    this.selectAllCheckboxTarget.checked = false;
    this.filterSelectZeroCheckboxTarget.checked = false;
    this.toggleFilterMenu();
    this.updateTableRowVisibility(
      ".bank-reconciliation .checkbox input",
      false,
    );
  }

  handleFilterSelectZeroClicked(e) {
    e.target.checked = true;
    this.selectAllCheckboxTarget.checked = false;
    this.filterSelectAllCheckboxTarget.checked = false;
    this.toggleFilterMenu();
    this.updateTableRowVisibility(".bank-reconciliation .checkbox input", true);
  }

  updateTableRowVisibility(selector, isHidden) {
    const elements = document.querySelectorAll(selector);
    elements.forEach((el) => {
      const parentNode = el.closest("tr");
      if (isHidden && el.checked) {
        parentNode.classList.add("hidden");
      } else {
        parentNode.classList.remove("hidden");
      }
    });
  }

  toggleFilterMenu() {
    document
      .querySelector("#bank-reconciliation-filter")
      .classList.toggle("hidden");
  }

  reloadCurrentBootgrid() {
    $("#account_table").bootgrid("reload");
  }
}
