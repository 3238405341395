import { Controller } from "stimulus"
import { splitDateToFormatArray, getSeparatorFromDateFormat } from 'helpers/date_helper';

export default class extends Controller {
  static targets = [ 'endDate', 'startDate' ]
  static values = {
    format: String,
    month: Number
  }

  connect() {
    this.startDate = this.startDateTarget
    this.endD = this.endDateTarget
    this.date_separator = getSeparatorFromDateFormat(this.formatValue);
  }

  startDateSet() {
    var dateText = this.startDate.value
    if(dateText === "") { return; }
    var formattedDateText = this.formatStringDate(dateText);
    var endDate = this.getEndDate(formattedDateText)
    this.endD.value = endDate.toLocaleDateString()
  }

  getEndDate(dateText) {
    var endDate = moment(dateText).add(this.monthValue, 'M').subtract(1, "days").toDate()
    var currentDate = new Date()
    if(currentDate < endDate) {
      notify('Your end date has been set to today as it cannot be in the future', 'success', '');
      return currentDate
    }
    return endDate
  }

  formatStringDate(date) {
    var separator = this.date_separator;
    var split_format =  this.formatValue.split(separator);
    var date_array = splitDateToFormatArray(date, separator, split_format);
    return `${date_array[2]}-${date_array[1]}-${date_array[0]} 00:00:00`
  }
}
