import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.enableOrDisableFormSubmitField();
  }

  updateCountAndSubmitButton() {
    updateSelectedCount();
    this.enableOrDisableFormSubmitField();
  }

  selectOrDeselectAll(event) {
    if (event.target.checked) {
      if (this.uncheckedCount() > 0) {
        $('.checkboxes:unchecked').click(); // Select visible rows
      }
    } else {
      if (this.checkedCount() > 0) {
        $('.checkboxes:checked').click(); // Unselect visible rows
      }
    }
    updateSelectedCount();
  }

  // Private

  checkedCount() {
    return $('.checkboxes:checked').not(':disabled').length
  }

  uncheckedCount() {
    return $('.checkboxes:unchecked').not(':disabled').length
  }

  enableOrDisableFormSubmitField(){
    if(this.checkedCount() > 0){
      enableFields($('#form-submit'));
    }else{
      disableFields($('#form-submit'));
    }
  }
}