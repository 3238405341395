import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "picker", "value" ]
  static values = {
    color: String
  }

  connect() {
    const picker = new JSColor(this.pickerTarget)
    picker.valueElement = this.valueTarget
    if (this.hasColorValue) {
      picker.fromString(this.colorValue)
      this.valueTarget.value = this.colorValue
    } else {
      picker.randomize()
    }
  }

}
