import LineItemRow from "./line_item_row_controller"
import { isNull } from "./helpers/_calculations"

const AMOUNT_TARGETS = [
  "quantityTarget",
  "priceTarget",
  "netAmountTarget",
  "taxAmountTarget",
  "totalAmountTarget"
]

const MISMATHS = "Warning this invoice has already been part credited " +
  "so Quantity x Price results in a greater total than is possible. Please amend these " +
  "figures as appropriate before saving"

export default class extends LineItemRow {

  static targets = [
    "stockTagField", "quantity", "price", "netAmount", "taxCode", "taxAmount",
    "totalAmount", "discount", "isSplitField", "category", "description","creditInFullCheckbox"
  ]

  static values = {
    locked: Boolean,
    isPartCredited: Boolean
  }

  connect() {
    this.setCreditInFullStateBasedOnTotal()
    allowEnterToTab()
    this.checkErrorState()
  }

  updatePriceNetTaxTotalFromQuantity() {
    this.validateCreditAmount(this.quantityTarget)
    if (isNull(this.priceTarget.value) && !isNull(this.totalAmountTarget)) {
      this.setTotal(null)
    } else if (isNull(this.quantityTarget.value)) {
      this.setPrice(null)
    }
    else if (!isNull(this.priceTarget.value) && !this.isLockedToVatReturn()) {
      this.calculateNet()
      this.updateCreditFromNetTax()
    }
    this.updateCreditNoteCardAndFullCreditNoteTotals()
  }

  updateQuantityNetTaxTotalFromPrice() {
    this.validateCreditAmount(this.priceTarget)
    if (isNull(this.quantityTarget.value) && !isNull(this.totalAmountTarget)) {
      this.setTotal(null)
    } else if (isNull(this.priceTarget.value)) {
      this.setQuantity(null)
    }
    else if (!isNull(this.quantityTarget.value) && !this.isLockedToVatReturn()) {
      this.calculateNet()
      this.updateCreditFromNetTax()
    }
    this.updateCreditNoteCardAndFullCreditNoteTotals()
  }

  updateCreditFromNetTax(){
    this.updateTaxTotalFromNet()
    if(this.isTotalGreaterThanMaxTotal()){
      this.totalAmountTarget.dispatchEvent(new Event("change"))
      }
  }


  updateQuantityPriceNetTaxFromCredit() {
    this.validateCreditAmount(this.totalAmountTarget)
    if (this.totalAmountTarget.value) {
      this.updateNetTaxFromTotal()
      if (!this.hasQuantityPriceDisabled()) {
        this.updateQuantityAndPriceFromNet()
      }
    } else {
      this.setAllFieldsToZero()
    }
    this.updateCreditNoteCardAndFullCreditNoteTotals()
  }

  updateQuantityAndPriceFromNet() {
    let originalCreditable = this.priceTarget.dataset.originalCreditable

    if (typeof(originalCreditable) === 'undefined') return

    this.setPrice(parseFloat(originalCreditable))

    // When credited in full, quantity may miscalculate due to decimal rounding
    if (this.creditInFullCheckboxTarget.checked) {
      this.quantityTarget.value = this.quantityTarget.dataset.originalCreditable
    } else {
      this.calculateQuantity()
    }
  }

  taxRate() {
    return this.taxCodeTarget.dataset.taxRate * 0.01
  }

  // Check credited amount is the same sign as invoiced amount
  // and does not exceed it, and is not NaN.
  // This is an ugly looking function, but it is essentially
  // just a bunch of math comparisons between two numbers.
  // You could almost use a mask for this.
  validateCreditAmount(element) {
    let credited = parseFloat(element.value)
    const invoiced = parseFloat(element.dataset.originalCreditable)
    if (isNaN(credited)) {
      credited = null
    } else if (invoiced < 0) {
      if (credited > 0) { credited = -credited }
      if (credited < invoiced) { credited = invoiced }
    } else if (invoiced > 0) {
      if (credited < 0) { credited = -credited }
      if (credited > invoiced) { credited = invoiced }
    } else { // invoiced is 0
      credited = 0
    }
    element.value = credited
  }

  updateCreditNoteCardAndFullCreditNoteTotals() {
    this.setCreditInFullStateBasedOnTotal()
    this.totalAmountTarget.dispatchEvent(new Event("totalChange"))
    updateCustomerDocumentAmounts()
  }

  hasQuantityPriceDisabled() {
    return this.quantityTarget.disabled && this.priceTarget.disabled
  }

  toggleCreditInFull(event) {
    event.target.checked ? this.creditInFull() : this.setAllFieldsToZero()
    this.updateCreditNoteCardAndFullCreditNoteTotals()
  }

  checkErrorState () {
    const error = $([this.quantityTarget, this.priceTarget])
    const titles = $(AMOUNT_TARGETS.map(t => this[t]))
    if (this.amountsDoNotAddUp()) {
      error.addClass('error')
      titles.prop('title', MISMATHS)
    } else {
      error.removeClass('error')
      titles.prop('title', '')
    }
  }

  creditInFull() { //Expected behaviour is that the total, net and tax are correct, but quantity and price is always max
    this.totalAmountTarget.value = this.totalAmountTarget.dataset.originalCreditable;
    this.totalAmountTarget.dispatchEvent(new Event("change"))
    this.updateQuantityAndPriceFromNet()
  }

  // When part credited already, the sums may not add up correctly. The actual total is calculated from the original quantity and price,
  // and that value is compared to the dom total value. This is why the only getters used are for quantity and price.
  amountsDoNotAddUp() {
    const quantity = this.getQuantity();
    const price = this.getPrice();
    if (!quantity || !price) { return false; }
    if (!this.isPartCredited()) { return false; }
    const discount = this.getDiscount()/100;
    const discounted_net = quantity*price*(1-discount);
    const discounted_tax = discounted_net * this.taxRate();
    const true_total = discounted_net + discounted_tax;
    return Math.abs(true_total - this.getTotal()) > this.errorMargin();
  }

  errorMargin(){
    const quantity = this.getQuantity();
    const price = this.getPrice();
    const min = (Math.abs(quantity) - 0.001) * (Math.abs(price) - 0.001);
    const max = (Math.abs(quantity) + 0.01) * (Math.abs(price) + 0.001);
    const raw_margin = Math.abs(max)-Math.abs(min)
    return Math.ceil(raw_margin * 100)/100;
  }

  isPartCredited(){
    return this.isPartCreditedValue
  }

  isCreditInFullCheckboxTicked(){
    return this.creditInFullCheckboxTarget.checked
  }

  isTotalMaxTotal(){
    const total = this.totalAmountTarget
    return parseFloat(total.value) == parseFloat(total.dataset.originalCreditable)
  }

  isTotalGreaterThanMaxTotal(){
    const total = this.totalAmountTarget
    return parseFloat(total.value) > parseFloat(total.dataset.originalCreditable)
  }

  setCreditInFullStateBasedOnTotal(){
    this.creditInFullCheckboxTarget.checked = this.isTotalMaxTotal();
  }
}
