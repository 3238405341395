export class DateHelper {
  constructor(date, format="DD/MM/YYYY") {
    this.date = localizeFromString(date, format);
  };

  addYear() {
    this.date = moment(this.date).add(11, 'months');
    return this;
  };

  subtractYear() {
    this.date = moment(this.date).subtract(11, 'months');
    return this;
  };

  toDate() {
    return this.date;
  };

  toISOFormat() {
    return moment(this.date).format("YYYY-MM-DD");
  };

  toMonthFormat() {
    return moment(this.date).format("MM/YYYY");
  }
};

function localizeFromString(date, format) {
  const parts = date.split("/");

  if (format == "MM/DD/YYYY") { return new Date(date) };

  if (format == "MM/YYYY") {
    const year = parts[1];
    const month = parts[0] - 1;
    const day = "01";

    return new Date(year, month, day);
  };

  const year = parts[2];
  const month = parts[1] - 1;
  const day = parts[0];

  return new Date(year, month, day);
};

export function splitDateToFormatArray(date, separator, split_format) { 
  var new_date_array = [];
  date.split(separator).forEach(function(date_value, index) {
    var new_date_value = getDateValueForArray(date_value, index, split_format);
    if (!isNaN(new_date_value)) { new_date_array.push(new_date_value); }
  })
  return new_date_array
}

function dateIsInRangeAndUnitIsNotYear(date, unit) {
  return (unit != "yyyy" && date > 0 && date <= 9)
}

function getDateValueForArray(date_value, index, split_format) {
  var format_unit = split_format[index].toLowerCase();
  var date_as_int = parseInt(date_value)
  if (!isNaN(date_as_int) && dateIsInRangeAndUnitIsNotYear(date_as_int, format_unit)) {
    date_as_int = "0" + date_as_int
  }
  return date_as_int
}

export function getSeparatorFromDateFormat(date_format) {
  return date_format.replaceAll(/[^/|-]/g, "")[0]   
}
