import { Controller } from "stimulus";

export default class ProfitLossComparisonsController extends Controller {
  static targets = ["filterRows", "filterFormSubmitButton"];

  numberOfFilterRows = 0;
  wasFilteredBefore = false;
  filterWasInitialized = false;
  firstRowBgClass = 'tw-bg-blue-50';

  initialize() {
    this.addComparison();
  }

  connect() {
    document.addEventListener("sortable-stopped", this.afterSorting.bind(this));
    document.addEventListener(
      "enterprises-select-updated",
      this.markAsNotFiltered.bind(this),
    );
  }

  disconnect() {
    document.removeEventListener(
      "sortable-stopped",
      this.afterSorting.bind(this),
    );
    document.removeEventListener(
      "enterprises-select-updated",
      this.markAsNotFiltered.bind(this),
    );
  }

  afterSorting() {
    this.reorderComparisons();
    this.markAsNotFiltered();
  }

  addComparison(e) {
    if (e) e.preventDefault();

    // small delay to allow enterprise/tags select boxes initialization do their thing...
    setTimeout(() => {
      const template = document.querySelector("template");
      const container = this.createTemplateRow(template);

      // if we're getting an event it means that a 'plus' button has been clicked
      // and we want to insert the new row immediately below the emitter, otherwise
      // we're in the controller initialization. No event in this case but appending
      // is just fine.
      if (e) {
        e.target.parentElement.parentElement.parentElement.insertAdjacentElement(
          "afterend",
          container,
        );
      } else {
        this.filterRowsTarget.append(container);
      }

      ++this.numberOfFilterRows;

      // If this is the first row, set a different background colour
      if (this.numberOfFilterRows === 1) {
        this.filterWasInitialized = true;
        this.filterRowsTarget
          .getElementsByClassName("filter-row")[0]
          .classList.add(this.firstRowBgClass);
        // Since we always want the primary data and an initial comparison
        // lets do some recursion
        this.addComparison();
      }

      this.markAsNotFiltered();
      this.reorderComparisons();
      initDatepickerComponent();
    }, 10);
  }

  removeComparison(e) {
    if (e) e.preventDefault();

    const rowToRemove = e.target.closest(".filter-row");

    if (this.canRemoveComparison(rowToRemove)) {
      rowToRemove.remove();
      --this.numberOfFilterRows;
      this.reorderComparisons();
      this.markAsNotFiltered();
    }
  }

  canRemoveComparison(rowToRemove) {
    return this.numberOfFilterRows > 2;
  }

  reorderComparisons(e) {
    if (e) e.preventDefault();
    const rows = this.filterRowsTarget.querySelectorAll(".filter-row");

    rows.forEach((element, index) => {
      element.classList.remove(this.firstRowBgClass);
      this.updateComparisonTitle(element, index);
    });
  }

  updateComparisonTitle(row, index) {
    let newTitle;

    if (index === 0) {
      newTitle = "Primary Data";
      row.classList.add(this.firstRowBgClass);
    } else {
      newTitle = `Comparison #${index}`;
    }

    row.querySelector(".comparison-title-label").textContent = newTitle;
    row.querySelector(".comparison-title").value = newTitle;
  }

  setTemplateVariables() {
    let id = Date.now().toString();

    return {
      id: id,
      title:
        this.numberOfFilterRows === 0
          ? "Primary Data"
          : `Comparison #${this.numberOfFilterRows}`,
    };
  }

  createTemplateRow(template) {
    const clonedNode = template.content.cloneNode(true);
    let tempDiv = document.createElement("div");
    tempDiv.appendChild(clonedNode);
    const htmlContent = this.replaceTemplateVariables(tempDiv.innerHTML);
    let container = document.createElement("div");
    container.innerHTML = htmlContent;

    return container;
  }

  replaceTemplateVariables(content) {
    return content.replace(
      /\${(.*?)}/g,
      (_, x) => this.setTemplateVariables()[x],
    );
  }

  needsToBeFiltered() {
    return this.wasFilteredBefore && this.filterWasInitialized;
  }

  onClickFilter() {
    this.wasFilteredBefore = true;
    this.filterFormSubmitButtonTarget.classList.remove("btn-warning");
    this.filterFormSubmitButtonTarget.classList.add("btn-success");
  }

  markAsNotFiltered() {
    if (this.needsToBeFiltered()) {
      this.filterFormSubmitButtonTarget.classList.remove("btn-success");
      this.filterFormSubmitButtonTarget.classList.add("btn-warning");
    }
  }
}
