import { Controller } from 'stimulus';

// This file was originally forked from pandles 'input_restriction',
// but is included as a new file since they are likely to diverge
// further. Views can therefore use either vanilla pandle logic or this.

const POSITIVE = new RegExp(/[0-9|.]/)
const NUMERIC = new RegExp(/[0-9|.|-]/)

export default class extends Controller {

  static targets = ['input']

  inputMaskPositive(event) {
    this.amountOnly(event)
    this.limitDecimal(event)
  }

  inputMaskPositive3dp(event) {
    this.amountOnly(event)
    this.limitDecimal(event, 3)
  }

  inputMaskNumeric(event) {
    this.amountOnly(event, true)
    this.limitDecimal(event)
  }

  inputMaskNumeric3dp(event) {
    this.amountOnly(event, true)
    this.limitDecimal(event, 3)
  }

  amountOnly(event, allow_negative = false) {
    const allowed_input_values = allow_negative ? NUMERIC : POSITIVE
    const not_alphabet_keycode = event.keyCode <= 48

    if(!allowed_input_values.test(event.key) && !not_alphabet_keycode) {
      event.preventDefault();
    }
  }

  limitDecimal(event, decimalPoints = 2) {
    if (event.keyCode <= 48) { return; }
    const input_value = this.getNewInput(event)
    const decimal_index = input_value.indexOf('.')
    if (decimal_index > 0 && input_value.substr(decimal_index).length > 1 + decimalPoints) {
      event.preventDefault();
    }
  }

  getNewInput(event) {
    var current_value = event.srcElement.value;
    var event_key = String.fromCharCode(event.charCode || event.keyCode);
    var current_value_array = current_value.split("");
    current_value_array.splice(event.target.selectionStart, (event.target.selectionEnd - event.target.selectionStart), event_key);
    return current_value_array.join("");
  }


  formatTo3dp(event){
    this.formatToNdp(event, 3)
  }

  formatTo2dp(event){
    this.formatToNdp(event, 2)
  }

  formatToNdp(event, N){
    const current_value = event.srcElement.value;
    if (this.isBlank(current_value)) { return }
    const multiple = Math.pow(10, N)
    event.srcElement.value = (Math.round(current_value * multiple) / multiple).toFixed(N);
  }

  isBlank = (x) => x === null || x === undefined || x === ''
}

