import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['counter']

  decrementTabCounter(event) {
    if (!isFeatureToggleActive('tab_counter')) { return; }
    let new_value = this.getValue() - 1;
    this.setValue(new_value);
  }

  incrementTabCounter(event) {
    if (!isFeatureToggleActive('tab_counter')) { return; }
    let new_value = this.getValue() + 1;
    this.setValue(new_value);
  }

  setTabCounterValue(event) {
    if (!isFeatureToggleActive('tab_counter')) { return; }
    let new_value = event.detail.new_value;
    this.setValue(new_value);
  }

  getValue() {
    return parseFloat(this.counterTarget.innerHTML)
  }

  setValue(new_value) {
    if (new_value < 0) { new_value = 0; }
    this.counterTarget.innerHTML = new_value;

    if (new_value <= 0 && !this.counterTarget.hidden) {
      this.counterTarget.hidden = true;
    } else if (new_value > 0 && this.counterTarget.hidden) {
      this.counterTarget.hidden = false;
    }
  }
}

