import { Controller } from "stimulus"

export default class extends Controller {

  static classes = [ "hidden" ]
  static targets = [ "content", "spinner" ]

  show(event) {
    $.ajax({
      method: 'get',
      url: event.detail,
      dataType: 'script',
    }).done(() => { this.hideSpinner.call(this) })
    this.hideAndTagSplitModal()
    this.element.hidden = false
  }

  hide(event) {
    this.element.hidden = true
    this.showAndUntagSplitModal()
    this.showSpinner()
    this.contentTarget.innerHTML = ""
  }

  hideSpinner() {
    this.spinnerTarget.classList.add(this.hiddenClass)
  }

  showSpinner() {
    this.spinnerTarget.classList.remove(this.hiddenClass)
  }

  hideAndTagSplitModal() {
    const visibleSplitModal = Array.prototype.slice.call(document.querySelectorAll(".modal")).filter(function (item,index) { return item.style.display === "block" })[0]
    if (visibleSplitModal) {
      visibleSplitModal.style.display = "none"
      visibleSplitModal.dataset.openModal = true
    }
  }

  showAndUntagSplitModal() {
    const taggedModal = document.querySelector("[data-open-modal=true]")
    if (taggedModal) {
      taggedModal.style.display = "block"
      taggedModal.dataset.openModal = false
    }
  }

}
