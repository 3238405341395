import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['linkId']

  connect() {
  }

  createNewQuickLink(event) {
    event.preventDefault();
    event.stopPropagation();
    var controller = this;
    $.post(
      Routes.user_custom_quick_links_path({format: 'js'}),
      { custom_quick_links: {
          path: controller.getPageUrl()
      }}
    )
  }

  showDeleteWarning(event) {
    var link_id = $(event.target).closest('li').data('link-id');
    return sweetAlert({
      title: 'Warning!',
      text: "Are you sure you want to delete this link?",
      type: 'warning',
      allowEscapeKey: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: "sweetalert-scary-warning"
    }, isConfirmed => this.deleteQuickLink(isConfirmed, link_id) );
  }

  deleteQuickLink(isConfirmed, link_id) {
    if (!isConfirmed) { return; }
    $.ajax({
      url: Routes.user_custom_quick_link_path({ format: 'js', id: link_id }),
      method: 'delete'
    })
  }

  getPageUrl() {
    var pathname = window.location.pathname;
    if (this.currentPageUsesTabs()) {
      var activeTab = this.getActiveTabElement().children[0]; 
      var tab_name = activeTab.id.split('_')[0]; // Similar to getTabbedFormTabId
      var query_params = "?tab=" + tab_name;
    } else{
      var query_params = window.location.search;
    }
    return pathname + query_params;
  }

  currentPageUsesTabs() {
    return Boolean(document.querySelector('#main .tab-nav') != null);
  }

  getActiveTabElement() {
    return document.querySelector('#main .tab-nav .active');
  }
}
