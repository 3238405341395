import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['form']

  connect(){
    new Card({
      form: this.formTarget,
      container: '.card-wrapper',
      formSelectors: {
        nameInput: '#name' // optional - defaults input[name="name"] changing to not use name so it doesn't get sent to the server
      },
      placeholders: {
        number: '•••• •••• •••• ••••',
        name: 'Joe Bloggs',
        expiry: '••/••',
        cvc: '•••'
      }
    });
  }
}