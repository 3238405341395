import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
     "field1", "field2"
    ]


  switchFields(){
    if(this.field1Target.value){
      this.field2Target.value = this.field1Target.value;
      this.field1Target.value = null;
    }
    else if(this.field2Target.value){
      this.field1Target.value = this.field2Target.value;
      this.field2Target.value = null;
    }
    this.submitFieldEvents();
  }

  submitFieldEvents(){
    this.field1Target.dispatchEvent(new Event("change"));
    this.field2Target.dispatchEvent(new Event("change"));
  }
}