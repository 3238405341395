// Stimulus actions cannot be setup for Multipicker directly.
// A custom event is needed to be setup to allow the function to be called
import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    $(this.element).on('changed.bs.select', function(event, index) {
      var selector_values = $(event.target).val()
      if (selector_values && selector_values[index] == '-1') {
        this.dispatchEvent(new CustomEvent("multi-change-all"));
      } else {
        this.dispatchEvent(new CustomEvent("multi-change"));
      }
    })
  }

  disconnect() {
    $(this.element).off();
  }
}
