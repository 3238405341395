import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "tagSelect", "tagFilter", "chosenTag" ]

  check(event) {
    const selectedId = this.tagSelectTarget.dataset.groupAndTagSelectsSelectedIdValue
    if (selectedId) {
      const id = "enterprise_tag_ids_" + selectedId
      const check_box = document.getElementById(id)
      check_box.checked = true
      check_box.closest('label').style.display = 'block'
      this.clearFilter()
    }
  }

  uncheck(event) {
    const id = event.target.dataset.value
    const check_box = document.getElementById(id)
    check_box.checked = false
    check_box.closest('label').style.display = 'none'
  }

  clearFilter() {
    this.tagFilterTarget.value = ''
    this.tagFilterTarget.dispatchEvent(new Event('input'))
  }
 
  filterTags(event) {
    const tags = this.chosenTagTargets
    const filterValue = event.target.value
    tags.forEach(function(tag) {
      if (tag.querySelector('input').checked) {
        if (filterValue) {
          if (tag.dataset.name.includes(filterValue.toLowerCase())) {
            tag.style.display = ''
          } else {
            tag.style.display = 'none'
          }
        } else {
          tag.style.display = ''
        }
      }
    })
  }

}
