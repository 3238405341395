import consumer from "./consumer"

if ($('meta[name=action-cable-url]').length) {
  consumer.subscriptions.create("FeedImportsChannel", {
    connected: function() {
      // Called when the subscription is ready for use on the server
    },

    disconnected: function() {
      // Called when the subscription has been terminated by the server
    },

    received: function(data) {
      if (data['error']) {
        notify(data['error'], 'danger', 'Error importing transactions: ' );
      } else {
        notify('Your ' + data['type'] + ' is complete, click here to view to view imported transactions', 'success', 'Success! ',null, data['link']);
      }
    }
  });
}