import { Controller } from 'stimulus';
import { updateFilterForSelector, toggleResetButtonDisable, toggleFilterButtonDisable, toggleFilterRowVisiblity, resetDatepickersAndSelectors, setSelectorsToFilterValues, updateSelectorValue } from 'helpers/advanced_search';

import { sendFilter } from 'helpers/bootgrid_helper';

export default class extends Controller {
  static targets = ['table', 'resetButton', 'advancedSearchRow']

  connect() {
    this.activeTable = this.getActiveTable(this.tableTargets)
    this.filters = JSON.parse(this.activeTable.dataset.recordFilters);
    if (isFeatureToggleActive('stock_item_filter')) {
      toggleFilterRowVisiblity(this.filters, this.advancedSearchRowTarget);
      toggleResetButtonDisable(this.filters, this.resetButtonTarget);
    }
    this.initDisableFilterButtonEvent(this.activeTable)
  }

  filterSelection(event) {
    var selector = event.target;
    this.filters = updateFilterForSelector(this, selector);
    updateSelectorValue(selector, this.filters);
    toggleResetButtonDisable(this.filters, this.resetButtonTarget);
    sendFilter(this.activeTable, this.filters);
  }

  resetCurrentSelection(event) {
    $(event.target).val([]);
    this.filterSelection(event);
  }

  resetFilters(event) {
    event.preventDefault();
    resetDatepickersAndSelectors();
    this.filters = {};
    sendFilter(this.activeTable, this.filters);
    toggleResetButtonDisable(this.filters, this.resetButtonTarget);
  }

  onTabChange(event) {
    var tab_name = event.target.id
    if (tab_name == "") { tab_name = event.target.parentElement.id }
    tab_name = tab_name.replace("_tab", "");
    this.activeTable = document.querySelector('#' + tab_name + ' table');
    this.initDisableFilterButtonEvent(this.activeTable)
    this.filters = JSON.parse(this.activeTable.dataset.recordFilters);
    $(this.activeTable).bootgrid('reload');
    setSelectorsToFilterValues(this.filters);
    toggleFilterRowVisiblity(this.filters, this.advancedSearchRowTarget);
    toggleResetButtonDisable(this.filters, this.resetButtonTarget);
  }

  getActiveTable(tables) {
    if (tables.length == 1) { return tables[0] }
    return tables.filter(function(target) { return target.parentElement.classList.contains('active') })[0]; 
  }

  initDisableFilterButtonEvent(table) {
    $(table).off('loaded.rs.jquery.bootgrid');
    $(table).on('loaded.rs.jquery.bootgrid', function(event) {
      var filters = JSON.parse(this.dataset.recordFilters);
      toggleFilterButtonDisable(this, filters);
    })
  }
}
