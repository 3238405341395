import { Controller } from "stimulus"

export default class extends Controller {

  connect(){
    $(this.element).bootgrid({
      css: {
        icon: 'zmdi icon',
        iconColumns: 'zmdi-view-module',
        iconDown: 'zmdi-caret-down',
        iconRefresh: 'zmdi-refresh',
        iconUp: 'zmdi-caret-up',
        iconExport: 'zmdi-download',
        iconFilterOptions: 'zmdi-filter-list',
        iconLoading: 'zmdi-rotate-right zmdi-hc-spin'
      }
    })
  }
}