import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "field", "headerButton", "dataButton" ]

  filterFields() {
    $(this.allOptions()).each((_, o) => this.removeSelected(o))
  }

  disableFieldsPrior(event) {
    const rowNumber = event.target.id.slice(-1)

    this.dataButtonTargets.forEach(target => target.disabled = false)
    this.dataButtonTargets.forEach(target => {
      if (target.id.slice(-1) <= rowNumber) {
        target.checked = false;
        target.disabled = true;
      }
    })
  }

  disableFieldsAfter(event) {
    const rowNumber = event.target.id.slice(-1)

    this.headerButtonTargets.forEach(target => target.disabled = false)
    this.headerButtonTargets.forEach(target => {
      if (target.id.slice(-1) >= rowNumber) {
        target.checked = false;
        target.disabled = true;
      }
    })
  }

  allOptions() {
    return $(this.fieldTargets).find('div.chosen-drop ul.chosen-results li')
  }

  selectedOptions() {
    return Array.from($(this.fieldTargets).find('a.chosen-single span'), o => $(o).text())
  }

  optionIsRemovable(option) {
    return this.selectedOptions().filter(o => this.allowedOnce(o)).includes($(option).text())
  }

  allowedOnce(option) {
    return ["Date", "Description"].includes(option)
  }

  removeSelected(options) {
    $(options).each((_, o) => {
      if (this.optionIsRemovable(o)) { $(o).remove() }
    })
  }
}
