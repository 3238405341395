import { Controller } from "stimulus"
import { initBankAccountWidgetClickEvent } from 'helpers/widgets/bank_account_widget';

export default class extends Controller {
  static values = {
    use_currency: Boolean,
    integer_value: Boolean,
    type: String
  }

  connect(){
    switch(this.typeValue) {
      case 'pie':
        initPieChartOrDisplayNoData($(this.element));
        break;
      case 'bar':
        initBarChartOrDisplayNoData($(this.element), this.useCurrencyValue, this.integerValue);
        initBankAccountWidgetClickEvent();
        break;
      default:
        initBarChartOrDisplayNoData($(this.element), this.useCurrencyValue, this.integerValue);
    }
  }
}
