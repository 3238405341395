import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["balanceTotal", "expectedTotal", "amountLeft"];

  static values = {
    id: String,
  };

  connect() {
    var element = document.getElementById(`split_total_balance${this.idValue}`);
    this.mutationObserver = new MutationObserver((mutations) =>
      this.onChange(mutations),
    );
    this.mutationObserver.observe(element, {
      attributes: true,
      childList: true,
      subtree: true,
    });
  }

  disconnect() {
    this.mutationObserver.disconnect();
  }

  getBalanceTotal() {
    return parseFloat(this.sanitize(this.balanceTotalTarget.innerHTML));
  }

  getExpectedTotal() {
    return parseFloat(this.sanitize(this.expectedTotalTarget.innerHTML));
  }

  sanitize(text) {
    return text.replace(/,/g, "");
  }

  onChange(_mutations) {
    const amountLeft = this.getExpectedTotal() - this.getBalanceTotal();
    this.amountLeftTarget.innerHTML = amountLeft.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    if (amountLeft !== 0) {
      this.amountLeftTarget.style.color = "#f44336";
    } else {
      this.amountLeftTarget.style.color = "#5cb85c";
    }
  }
}
