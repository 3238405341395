import consumer from "./consumer"

window.createSubscriptionToBackgroundExports = function(id, export_button_span, initial_button_class, callback) {  
  consumer.subscriptions.create({ channel: "BootgridTableBackgroundExportChannel", id: id }, {
    connected: function() {
      // Called when the subscription is ready for use on the server
      callback();
    },

    disconnected: function() {
      // Called when the subscription has been terminated by the server
    },

    received: function(data) {
      onBackgroundExportPollResponse(data);
      restoreCsvButton(export_button_span, initial_button_class);
      consumer.subscriptions.remove(this)
    },
  });

  function onBackgroundExportPollResponse(response) {
    if (response.status == 'Complete') {
      notify('Your report is ready!', 'success', 'Success! ');
      var win = window.open(Routes.bootgrid_table_background_export_path(response.id, {format: "html"}), "_blank");
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      } else {
        //Browser has blocked it
        alert('Please allow popups for this website so that your export can be downloaded');
      }

    } else {
      notify('An error occurred calculating your report, please try again or contact support', 'danger', 'Error ');
    }
  }

  function restoreCsvButton (export_button_span, old_button_class) {
    export_button_span.attr('class', old_button_class);
    export_button_span.parent().attr('disabled', false);
  }
}