import { Controller } from "stimulus";

// Handles values changes when importing customer/supplier invoices
// alerting the user on the first time they try to edit an imported value
export default class extends Controller {
  static targets = ["totalAmount", "netAmount"];

  connect() {
    this.warned = false;
    this.modal = document.querySelector("#warning-modal");
    this.dateTimeButton = this.element.querySelector(".dateButton");
    this.initialTotalAmount = this.totalAmountTarget.value;
    this.initialNetAmount = this.netAmountTarget.value;

    this.handleModalButton();
    this.handleDateTimePickerButton();
  }

  handleModalButton() {
    this.modal &&
      this.modal.querySelector("button").addEventListener("click", () => {
        this.modal.style.display = "none";
      });
  }

  handleDateTimePickerButton() {
    this.dateTimeButton &&
      this.dateTimeButton.addEventListener("click", () => {
        this.warnValuesAreChanging();
      });
  }

  warnValuesAreChanging() {
    if (this.warned || !this.modal) return;

    this.warned = true;
    this.modal.style.display = "block";
  }

  valueChanged() {
    // Total changes are triggered from other controllers
    // so we need to set a short timeout to make sure we are reading the new field value
    setTimeout(() => {
      if (
        this.initialTotalAmount !== this.totalAmountTarget.value ||
        this.initialNetAmount !== this.netAmountTarget.value
      ) {
        this.warnValuesAreChanging();
      }
    }, 1);
  }

  disconnect() {
    $(this.element).off();
  }
}
