import { Controller } from "stimulus"

export class AlertController extends Controller {
  static values = {
    title: String,
    text: String,
    type: String,
    html: Boolean
  }

  displayAlert(event) {
    sweetAlert({
      title: this.titleValue,
      text: this.alertTextValue(event),
      type: this.typeValue,
      html: this.htmlValue
    });
  }

  alertTextValue(event) {
    return this.textValue
  }
}

export default AlertController;
