import { Controller } from "stimulus";
import { parseBoolean } from "helpers/boolean_parser";
import { roundValue, isZero, isNull } from "./helpers/_calculations";

let id = 0;

const READ_ONLY_IF_SPLIT = [
  "netAmount",
  "taxAmount",
  "totalAmount",
  "description",
];

const CLEAR_IF_SPLIT = ["categoryTarget", "taxCodeTarget", "enterpriseTarget"];
const STOCK_FIELDS = ["quantityTarget", "priceTarget"];
const STOCKLESS_TYPES = ["SalesReceipt", "PurchasePayment"];
const CHOSENS = ["category", "taxCode", "enterprise"];

export class LineItemRowBaseController extends Controller {
  static targets = [
    "stockTagField",
    "quantity",
    "price",
    "netAmount",
    "taxCode",
    "taxAmount",
    "totalAmount",
    "discount",
    "isSplitField",
    "category",
    "description",
    "btType",
    "enterprise",
  ];

  static values = {
    locked: Boolean,
    isSplit: Boolean,
    splitTable: Number,
  };

  initialize() {
    if (!this.id) this.id = id++;

    this.detachedSelectOptions = [];
    this.setInitialSplitStates();
    this.bindTaxCodeChangeEvent();
  }

  connect() {}

  wasPreviouslySplit = false;

  getQuantity = () => parseFloat(this.quantityTarget.value);
  getPrice = () => parseFloat(this.priceTarget.value);
  getNet = () => parseFloat(this.netAmountTarget.value);
  getTax = () => parseFloat(this.taxAmountTarget.value);
  getTotal = () => parseFloat(this.totalAmountTarget.value);

  splitCloneFirstRowInputFields() {
    return [
      "netAmountTarget",
      "taxAmountTarget",
      "totalAmountTarget",
      "descriptionTarget",
    ].concat(STOCK_FIELDS);
  }

  // Added to make it easier to override in the future if required
  splitCopyRowInputFields() {
    return [
      "netAmountTarget",
      "taxAmountTarget",
      "totalAmountTarget",
      "descriptionTarget",
    ].concat(STOCK_FIELDS);
  }

  bindTaxCodeChangeEvent() {
    // FARMPLAN: Added to fix issue recalculating tax amount when changing category/taxcode
    if (this.hasTaxCodeTarget) {
      $(this.taxCodeTarget).on(
        "change",
        function (_event) {
          this.updateFromNet();
        }.bind(this),
      );
    }
    // FARMPLAN

    // Wait for everything else to init first, than bam! Fix the tabbing
    setTimeout(() => window.allowEnterToTab(), 500);
  }

  getDiscount() {
    if (!this.hasDiscountTarget || this.discountTarget === undefined) {
      return 0;
    }
    return parseFloat(this.discountTarget.value) || 0;
  }

  // The net if you remove an already applied percentage discount
  getUndiscountedNet = () => (this.getNet() / (100 - this.getDiscount())) * 100;

  setQuantity = (n) => this.setValue(this.quantityTarget, n, 3);
  setPrice = (n) => this.setValue(this.priceTarget, n, 3);
  setNet = (n) => this.setValue(this.netAmountTarget, n, 2);
  setTax = (n) => {
    if (this.hasTaxAmountTarget) {
      this.setValue(this.taxAmountTarget, n, 2);
    }
  };
  setTotal = (n) => this.setValue(this.totalAmountTarget, n, 2);
  setValue = (n, v, d) => {
    n.value = isNull(v) ? v : v.toFixed(d);
  };

  clearForSplit() {
    if (this.hasStockTagFieldTarget) {
      if (!parseBoolean(this.isSplitFieldTarget.value)) {
        this.stockTagFieldTarget.dispatchEvent(new Event("clear"));
      }
    }

    // Dispatch custom event to populate split data
    window.dispatchEvent(new CustomEvent("split", { detail: this }));
  }

  updateFromTotal() {
    if (isNull(this.getTotal())) {
      this.setAllFieldsToZero();
    } else {
      this.updateNetTaxFromTotal();
      this.updateQuantityAndPriceFromNet();
    }
    this.updateExternalAmounts();
  }

  updateFromNet() {
    if (isNull(this.getNet())) {
      this.setAllFieldsToZero();
    } else if (isZero(this.getNet())) {
      this.clearStockValues();
      this.updateTaxTotalFromNet();
    } else {
      this.updateTaxTotalFromNet();
      this.updateQuantityAndPriceFromNet();
    }
    this.updateExternalAmounts();
  }

  updateFromTaxAmount() {
    if (!isNull(this.getNet())) {
      this.calculateTotalOnTaxAmount();
    }
  }

  updateFromQuantity() {
    if (!this.getQuantity()) {
      this.setQuantity(null);
      this.setPrice(null);
    } else if (this.isLockedToVatReturn()) {
      this.calculatePrice();
    } else {
      this.updateNetAndPriceFromQuantity();
      this.updateTaxTotalFromNet();
      this.updateExternalAmounts();
    }
  }

  updateFromPrice() {
    if (!this.getPrice()) {
      this.setQuantity(null);
      this.setPrice(null);
    } else if (this.isLockedToVatReturn()) {
      this.calculateQuantity();
    } else {
      this.updateNetAndQuantityFromPrice();
      this.updateTaxTotalFromNet();
      this.updateExternalAmounts();
    }
  }

  updateFromDiscount() {
    if (this.getQuantity() && this.getPrice()) {
      this.calculateNet();
      this.updateTaxTotalFromNet();
      this.updateExternalAmounts();
    }
  }

  updateNetTaxFromTotal() {
    const total = this.getTotal();
    const new_net = roundValue(total / (1 + this.taxRate()));
    const new_tax = total - new_net;
    this.setNet(new_net);
    this.setTax(new_tax);
  }

  updateTaxTotalFromNet() {
    const net = this.getNet();
    if (isNull(net)) {
      return;
    }

    const new_tax = roundValue(net * this.taxRate());
    const new_total = net + new_tax;
    this.setTax(new_tax);
    this.setTotal(new_total);
  }

  calculateTotalOnTaxAmount() {
    this.setTotal(this.getNet() + this.getTax());
  }

  updateQuantityAndPriceFromNet() {
    const quantity = this.getQuantity();
    const price = this.getPrice();
    if (!quantity && !price) {
      return;
    }
    if (!quantity) {
      this.calculateQuantity();
    } else {
      this.calculatePrice();
    }
  }

  updateNetAndPriceFromQuantity() {
    const price = this.getPrice();
    const net = this.getNet();
    if (!price && !net) {
      return;
    }
    if (!price) {
      this.calculatePrice();
    } else {
      this.calculateNet();
    }
  }

  updateNetAndQuantityFromPrice() {
    const quantity = this.getQuantity();
    const net = this.getNet();
    if (!net && !quantity) {
      return;
    }
    if (!quantity) {
      this.calculateQuantity();
    } else {
      this.calculateNet();
    }
  }

  calculatePrice() {
    const net = this.getUndiscountedNet();
    const quantity = this.getQuantity();
    this.setPrice(net / quantity);
    this.updateQuantityAndPriceSignFromPrice();
  }

  calculateQuantity() {
    if (isNull(this.getUndiscountedNet())) return;
    this.setQuantity(this.getUndiscountedNet() / this.getPrice());
  }

  calculateNet() {
    const base = this.getPrice() * this.getQuantity();
    const discount = base * (this.getDiscount() / 100);
    this.setNet(base - discount);
  }

  updateQuantityAndPriceSignFromPrice() {
    if (this.getPrice() < 0) {
      this.priceTarget.value *= -1;
      this.quantityTarget.value *= -1;
    }
  }

  // Quantities with bank receipts/supplier payments are not implemented
  // currently. They would need AC-ing
  typeChange() {
    if (STOCKLESS_TYPES.includes(this.btTypeTarget.value)) {
      STOCK_FIELDS.forEach((f) => $(this[f]).attr("disabled", true).val(""));
    } else {
      STOCK_FIELDS.forEach((f) => $(this[f]).attr("disabled", false));
    }
  }

  // When split status changes
  splitChange() {
    const isSplit = this.isSplit();

    if (isSplit) {
      CLEAR_IF_SPLIT.forEach((f) => {
        try {
          $(this[f]).val(null);
        } catch (e) {}
      });
      this.setSplitStates();
    } else {
      if (this.wasPreviouslySplit) {
        STOCK_FIELDS.forEach((f) => {
          try {
            $(this[f]).val("");
          } catch (e) {}
        });
      }
    }
    READ_ONLY_IF_SPLIT.forEach((f) => {
      try {
        $(this[`${f}Target`]).attr("readonly", isSplit);
      } catch (e) {}
    });
    CHOSENS.forEach((f) => {
      try {
        $(this[`${f}Target`])
          .attr("disabled", isSplit)
          .trigger("chosen:updated");
      } catch (e) {}
    });
    STOCK_FIELDS.forEach((f) => {
      try {
        $(this[f]).attr("disabled", isSplit);
      } catch (e) {}
    });
    this.wasPreviouslySplit = isSplit;
  }

  // When locked the net amount can not change
  isLockedToVatReturn() {
    return this.lockedValue;
  }

  taxRate() {
    if (this.hasTaxCodeTarget) {
      return getTaxRateFromTaxCodeSelect($(this.taxCodeTarget)) * 0.01 || 0;
    } else {
      return 0;
    }
  }

  setAllFieldsToZero() {
    this.setQuantity(null);
    this.setPrice(null);
    this.setNet(null);
    this.setTax(null);
    this.setTotal(null);
  }

  clearStockValues() {
    this.setQuantity(null);
    this.setPrice(null);
  }

  setInitialSplitStates() {
    if (!this.isSplit()) return;
    this.wasPreviouslySplit = this.isSplit();
    this.setSplitStates();
  }

  updateExternalAmounts() {
    updateSplitBalance(this.splitRow()); //update splits totals
    updateCustomerDocumentAmounts(); //update totals
  }

  isSplit() {
    if (!this.hasIsSplitFieldTarget) return false;

    return this.isSplitFieldTarget.value == "true";
  }

  splitRow() {
    return $(this.totalAmountTarget).closest(".split_row");
  }

  setSplitStates() {
    STOCK_FIELDS.concat(CLEAR_IF_SPLIT).forEach((f) => {
      try {
        $(this[f]).val("Split");
      } catch (e) {}
    });
  }

  clickCopySplitRow() {
    // We need to wait here for the row to be created first by Pandle JS code
    setTimeout(() => {
      window.dispatchEvent(
        new CustomEvent("copySplitRowEvent", { detail: this }),
      );
    }, 1);
  }

  // Populates the first split row with the parent row
  populateFirstSplitRow(e) {
    const emitter = e.detail;

    // guard clause to stop unrelated controller instances to respond to the event
    if (this.id - emitter.id < 1 || emitter.categoryTarget.disabled) return;

    this.copyDataFromOtherInstance(emitter);
  }

  // Copies a split row to the last new created one
  copySplitRow(e) {
    const emitter = e.detail;

    if (!this.instancesFromSameSplitTable(emitter, this)) {
      return;
    }

    const table = this.element.parentNode;
    var lastRow = table.rows[table.rows.length - 3];

    if (this.element === lastRow) {
      this.copyDataFromOtherInstance(emitter, true);
      updateSplitBalance(this.splitRow());
    }
  }

  // When copying split rows to a new row, figures should be copied over as well
  // even on imported bank transactions (https://yozudev.atlassian.net/browse/FP-1335).
  // Exception when cloning the first split row from the parent non split form, where figures are not copied.
  // *****
  // NOTE: this method contains some bizarre and hacky behaviour to work around
  // chosen-select issues:
  // 1) FP-1370: when splitting an MO transaction, the category was not getting
  // copied across. Detaching disabled options before the chosen:updated event
  // was added as workaround for this, but:
  // 2) FP-1665: It gets very slow to do this once there are more than ~6 rows
  // so we only do this for the initial split, which is probably the only time
  // it is relevant.
  // 3) If you are about to add a third case here, then it is probably time we
  // ditched the deprecated chosen-select and refactor a new dawn! :p
  copyDataFromOtherInstance(instance, copyingRow = false) {
    const inputFieldTargets = copyingRow
      ? this.splitCopyRowInputFields()
      : this.splitCloneFirstRowInputFields();

    inputFieldTargets.forEach((f) => {
      try {
        this[f].value = instance[f].value;
      } catch (e) {}
    });

    CLEAR_IF_SPLIT.forEach((f) => {
      if (!copyingRow) { this.detachHiddenSelectOptions(f) } // See note

      try {
        this[f].value = instance[f].value;
        this[f].dispatchEvent(new Event("chosen:updated"));
      } catch (e) {}

      if (!copyingRow) { this.attachHiddenSelectOptions(f) } // See note
    });
  }

  detachHiddenSelectOptions(target) {
    // On bank transactions, Pandle renders some duplicated select options and hides the ones out of scope
    // This causes issues when setting new chosen entries so this removes all hidden duplicates.
    // Note: chosen JQuery library causes several issues all over the place and would be nice to replace it.

    try {
      var select = this[target].getAttribute("id");
      if (!select) return;
      // Note there will be 1000s of options here- it is SLOW to process them
      var options = document.querySelectorAll(`#${select} > option:disabled`);

      options.forEach((option) => {
        this.detachedSelectOptions.push(option.parentNode.removeChild(option));
      });
    } catch {}
  }

  attachHiddenSelectOptions(target) {
    this.detachedSelectOptions.forEach((option) => {
      this[target].appendChild(option);
    });

    this.detachedSelectOptions = [];
  }

  instancesFromSameSplitTable(instance1, instance2) {
    return instance1.splitTableValue === instance2.splitTableValue;
  }

  isElementFromSplitRow() {
    return Array.from(this.element.classList).includes("split_row");
  }
}
