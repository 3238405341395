import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['form', 'submitButton', 'nameField']

  static values = {
    clientId: String,
    publishableKey: String,
  }

  connect() {
    var stripe = Stripe(this.publishableKeyValue, { stripeAccount: this.clientIdValue });
    var elements = stripe.elements();
    // Setup the 3 card fields
    var number_elt = mountStripeElement(elements, 'cardNumber', '')
    addCardJsStyling(number_elt, false, '.jp-card-number', checkCardType);

    var expiry_elt = mountStripeElement(elements, 'cardExpiry', 'mm/yy')
    addCardJsStyling(expiry_elt, false, '.jp-card-expiry');

    var cvc_elt = mountStripeElement(elements, 'cardCvc', '')
    addCardJsStyling(cvc_elt, true, '.jp-card-cvc');
    
    var controller = this
    // Handle form behaviour
    this.submitButtonTarget.addEventListener("click", function(event) {
      event.preventDefault();
      controller.submitButtonTarget.disabled = true;
      stripe.createPaymentMethod('card', number_elt,
        { billing_details: { name: controller.nameFieldTarget.value } }
      ).then(controller.stripeInvoiceResponseHandler);
      return false;
    }, false);
  }

  stripeInvoiceResponseHandler(response) {
    var $form = $('#invoice_payment_form');
    if (response.error) {
      showErrorsOnInvoicePaymentForm(response.error.message);
    } else {
      var token = response.paymentMethod.id;
      $form.append($('<input type="hidden" name="invoice_payment[payment_method_id_stripe]" id="invoice_payment_stripe_token">').val(token));
      $form.trigger('submit.rails');
    }
  }

  unsuccessfulPaymentHandler(event) {
    var stripe = Stripe(this.publishableKeyValue, { stripeAccount: this.clientIdValue });
    stripe
      .handleCardAction(event.detail.client_secret)
      .then(function(result) {
        if (result.error) {
          showErrorsOnInvoicePaymentForm(result.error.message)
        } else {
          $.ajax({ url: event.detail.on_success_url, method: 'PATCH' })
        }
      });
  }
}