import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['reg_no']

  connect() {
    this.prohibitedCountries = ['GB', 'XI']
    this.numbersRegex = new RegExp(/\D/,'g')
  }

  restrictVatNumberToNumbersOnly(event){
    if (!isFeatureToggleActive('vat_number_uk_restriction')) { return; }
    var company_un_locode = window.companySettings.country.un_locode;
    var isUK = this.prohibitedCountries.includes(company_un_locode);

    if(isUK && this.valueContainsNonDigits()) {
      var new_value = this.reg_noTarget.value.replace(this.numbersRegex, '')
      this.reg_noTarget.value = new_value
    }
  }

  valueContainsNonDigits(){
    var reg_noValue = this.reg_noTarget.value;
    return Boolean(reg_noValue.match(this.numbersRegex));
  }
}
