import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "uploadsList", "emptyPlaceholder", "selectAllCheckbox"]
  static values = {
    id: String
  }

  reload() {
    fetch(Routes.invoice_attachments_path(this.idValue),
      { method: 'GET',
        credentials: 'include',
        headers: {"X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content} })
      .then((response) => response.text())
      .then((data) => { this.updateUploadList(data, this) })
  }

  updateUploadList(data, controller) {
    var listNode = $(controller.uploadsListTarget)
    var placeholderNode = $(controller.emptyPlaceholderTarget)
    var uploads = JSON.parse(data)["rows"]
    listNode.empty()
    placeholderNode.empty()
    if (uploads.length > 0) {
      uploads.forEach(function(upload) { listNode.append(controller.uploadItemElement(upload)) })
      if(controller.selectAllCheckboxTarget.hidden) { controller.selectAllCheckboxTarget.hidden = false }
    } else {
      placeholderNode.append(controller.emptyPlaceholderElement())
      if(!controller.selectAllCheckboxTarget.hidden) { controller.selectAllCheckboxTarget.hidden = true }
    }
  }

  uploadItemElement(upload) {
    return '<div class="upload-line-item">' +
        '<span>' + upload["attachment_name"] +'</span>' +
        '<span>| ' + upload["attachment_updated_at"] + '</span>' +
        '<label for="invoice_email_creator_uploads_upload_ids_' + upload["id"] + '">' +
        '<input data-checkbox-collection-target="checkbox" type="checkbox" value="' + upload["id"] + '" name="invoice_email_creator[uploads][upload_ids][]"' +
        ' id="invoice_email_creator_uploads_upload_ids_' + upload["id"] + '"></label>' +
      '</div>'
  }

  emptyPlaceholderElement() {
    return '<div class="max-height-200" data-uploads-list-target="uploadsList"></div>' +
      '<div class="upload-instructions" data-uploads-list-target="emptyPlaceholder">' +
        'There are currently no files uploaded against this invoice.' +
      '</div>'
  }
}
