import { Controller } from "stimulus"

export default class extends Controller {

  toggle(event) {
    const formData = new FormData()
    formData.append("enable", event.target.checked)
    formData.append("authenticity_token", document.querySelector("meta[name=csrf-token]").content)
    fetch(event.target.dataset.url, { method: "PUT", body: formData })
  }

}
