import { Controller } from "stimulus"
import { splitDateToFormatArray, getSeparatorFromDateFormat } from 'helpers/date_helper';

export default class extends Controller {
  static targets = ['inputDate']

  connect() {
    this.input_field = $(this.inputDateTarget);
    this.date_format = this.input_field.data('format');
    this.date_separator = getSeparatorFromDateFormat(this.date_format);
  }

  completeInput(event) {
    var controller = this;
    if (event.key != controller.date_separator) { return; }
    var current_date = controller.input_field.val();
    var new_date = controller.constructNewDate(controller, current_date);
    controller.setDate(controller.input_field, new_date);
  }

  autoCompleteDate(event) {
    var controller = this
    controller.input_field.change();
  }

  constructNewDate(controller, date) {
    var separator = controller.date_separator;
    var split_format = controller.date_format.split(separator);
    var date_array = splitDateToFormatArray(date, separator, split_format);
    return date_array.join(separator);
  }

  setDate($field, date) {
    $field.val(date);
  }
}
