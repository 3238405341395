import { selectorIsMultiPicker, updateMultiSelector, getMultiPickerValuesExcludingAll, resetMultiSelector, allOptionsSelected } from 'helpers/multipicker';

export function updateFilterForSelector(controller, selector) {
  var filter_name = selector.dataset.filterName;
  var values = getValuesFromSelector(selector);

  if (values.length == 0 || values == '-1' || allOptionsSelected(selector)) {
    delete controller.filters[filter_name];
  } else {
    controller.filters[filter_name] = values;
  }
  return controller.filters
}

export function toggleResetButtonDisable(filters, resetButton) {
  if (Object.keys(filters).length == 0) {
    resetButton.classList.add('disabled');
  } else {
    resetButton.classList.remove('disabled');
  }
}

export function toggleFilterButtonDisable(table, filters) {
  var disable_filter_button = Boolean(Object.keys(filters).length > 0);
  var table_bootgrid_action = document.getElementById(table.id + '-header')
  table_bootgrid_action.querySelector('.advanced-filter').disabled = disable_filter_button;
}

export function toggleFilterRowVisiblity(filters, advanceSearchElement) {
  if (Object.keys(filters).length != 0) {
    advanceSearchElement.classList.add("in");
  }
}

export function resetDatepickersAndSelectors() {
  Array.from(document.querySelectorAll(".multi-selectpicker")).forEach(function(selector) {
    resetMultiSelector(selector);
  });

  Array.from(document.querySelectorAll("#advanced-search .advanced-search-selector, #project_selector")).forEach(function(selector) {
    $(selector).val(-1).trigger('chosen:updated');
  });
}

export function setSelectorsToFilterValues(filters) {
  if (Object.keys(filters).length != 0) {
    Object.entries(filters).forEach(function(array) {
      var selector = document.querySelector("[data-filter-name=" + array[0] + "]")
      if (selectorIsMultiPicker(selector)) {
        updateMultiSelector(selector, array[1])
      } else {
        selector.value = array[1]
      }
    })
  } else {
    resetDatepickersAndSelectors();
  }
}

export function updateSelectorValue(selector, filters) {
  if (!selectorIsMultiPicker(selector)) { return; }
  var filter_name = selector.dataset.filterName;
  var filter_values = filters[filter_name]
  if (filter_values == undefined || filter_values.length == 0) {
    resetMultiSelector(selector);
  } else {
    updateMultiSelector(selector, filter_values);
  }
}

function getValuesFromSelector(selector) {
  if (selectorIsMultiPicker(selector)) {
    return getMultiPickerValuesExcludingAll(selector);
  } else {
    return selector.value;
  }
}
