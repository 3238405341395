// https://yozudev.atlassian.net/browse/FP-1677 
// fixes amountOnly accepting spaces eg spaces being saved to 
// redis user setting cache and producing Json::ParseErrors 

import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = ['input']

  inputMaskAmount(event) {
    this.amountOnly(event)
    this.limitDecimal(event)
  }

  amountOnly(event) {
    var allowed_input_values = new RegExp(/[0-9|.]/);
    var not_alphabet_keycode = event.keyCode <= 48;
    var is_space = new RegExp(/[\s]/); // Farmplan https://yozudev.atlassian.net/browse/FP-1677

    if((!allowed_input_values.test(event.key) && !not_alphabet_keycode) || is_space.test(event.key)) {
      event.preventDefault();
    } // Farmplan https://yozudev.atlassian.net/browse/FP-1677 
  }

  limitDecimal(event) {
    if (event.keyCode <= 48) { return; }
    var input_value = this.getNewInput(event)
    var decimal_index = input_value.indexOf('.')
    if (decimal_index > 0 && input_value.substr(decimal_index).length > 3) {
      event.preventDefault();
    }
  }

  getNewInput(event) {
    var current_value = event.srcElement.value;
    var event_key = String.fromCharCode(event.charCode || event.keyCode);
    var current_value_array = current_value.split("");
    current_value_array.splice(event.target.selectionStart, (event.target.selectionEnd - event.target.selectionStart), event_key);
    return current_value_array.join("");
  }
}

