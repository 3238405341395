export function initBankAccountWidgetClickEvent() {
  if(bankAccountChart().length == 0) { return; }
  initBarClickListener();
  initLabelClickListener();
  $("#bank_account_chart_loader").show();
}

function initBarClickListener() {
  bankAccountChart().bind("plotclick", function(event, _, item) {
    if (item) {
      var bank_account_id = bankAccountIds()[_getItemIndex(item)][1]
      openBankAccountModal(bank_account_id)
    }
  })
}

function _getItemIndex(item) {
  const MAX_ITEMS_BEFORE_ZERO_INDEX = 4;
  if (bankAccountIds().length > MAX_ITEMS_BEFORE_ZERO_INDEX) {
    return item.dataIndex
  } else {
    return item.dataIndex - 1
  } 
}

function initLabelClickListener() {
  bankAccountChart().find('.xAxis > div').each(function(_, element) {
    $(element).on('click', function(event) {
      var selected_bank_account_name = $(event.target).text();
      bankAccountIds().forEach(function(bank_account_array) {
        if (bank_account_array[0] == selected_bank_account_name) {
          openBankAccountModal(bank_account_array[1])
          return true;
        }
      })
    })
  })
}

function bankAccountChart() {
  return $('#bank_account_chart');
}

function bankAccountIds() {
  return bankAccountChart().data('value')[0].bank_account_ids;
}

function openBankAccountModal(bank_account_id) {
  $.ajax({ url: "bank_accounts/" + bank_account_id + "/transactions", method: 'GET', dataType: "script" })
}
