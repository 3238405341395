import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form" ]

  initialize() {
    this.formTarget.addEventListener("keydown", this.eventSetup.bind(this));
  }

  eventSetup(event) {
    if ((event.keyCode === 13 || event.keyCode === 9) && !event.shiftKey) {
      event.stopPropagation();
      event.preventDefault();

      $(document.activeElement).mouseleave();
      setTimeout(() => { this.enterKeyPress(document.activeElement) }, 250);
    }

    if ($('#imported_bank_transaction_form').length > 0) {
      $(':checkbox').keyup(event => {
        if (event.keyCode === 13 && !$(this).is('[disabled=disabled]')) {
          $(this).click();
        }
      });
    }
  }

  enterKeyPress(element) {
    let currentRow = $(element).closest('tr')[0];
    let currentCell = $(element).closest('td')[0];
    let lastRow = $(element).closest('tr').is(":last-child");
    let nextEnabledCell = this.findNextEnabledCell(currentCell);

    if ($(element).is('a') && !$(element).is('[disabled=disabled]')) {
      return $(element).click();
    }

    if (nextEnabledCell !== null && typeof(nextEnabledCell) !== 'undefined') {
      nextEnabledCell[0].focus();
    } else if ($(element).closest('tr').length > 0 && !lastRow) {
      console.log(currentCell)
      this.tabToNextRow(currentCell);
    } else if ($(currentRow).find("a[class*='add_new_']").length > 0) {
      $(currentRow).find("a[class*='add_new_']").click();
      var controller = this;
      setTimeout(function() {
        controller.tabToNextRow(currentCell);
      }, 300);
    } else {
      this.lookForNonSiblingCellToFocus(element);
    }
  };

  findNextEnabledCell(currentCell) {
    if ($(currentCell).next().length > 0) {
      let nextCell = $(currentCell).next();
      let formElementsInCell = $(nextCell).find('input:visible:enabled:not([readonly]):not([type="hidden"]), a:not([tabindex="-1"]):not(.disabled), select:visible:enabled:not([tabindex="-1"])');

      if ($(formElementsInCell).length > 0) {
        return formElementsInCell;
      }

      // FARMPLAN
      return this.findNextEnabledCell(nextCell); // Farmplan Bug Fix - remove this file once it comes through to FPBE
      // FARMPLAN END
    }
  }

  tabToNextRow(currentCell) {
    let nextCell = this.findFirstCellInNextRow(currentCell);
    if (typeof(nextCell) != 'undefined') {
      nextCell.focus();
    }
  }

  findFirstCellInNextRow(currentCell) {
    let nextRow = $(currentCell).parents('tr').next()[0];
    let nextCell = $(nextRow).find('td:first').find('input:visible:enabled:not([readonly]):not([type="hidden"]), select:visible:enabled:not([tabindex="-1"])');

    if (nextCell.length > 0) {
      return nextCell;
    }
  }

  // Used when the first cell doesn't contain a form field (or it's disabled)
  findFirstInputInNextRow(currentCell) {
    let nextRow = $(currentCell).parents('tr').next()[0];
    let nextInput = $(nextRow).find('input:visible:enabled:not([readonly]):not([type="hidden"]), select:visible:enabled:not([tabindex="-1"]):not([readonly])');
    let nextCell = nextInput.first();

    if (nextCell.length > 0) {
      return nextCell;
    }
  }

  findNextNonSiblingCell(currentCell) {
    if ($('#vat_return_form,#new_journal_entry,.vat_returns').length > 0) {
      let nextCell = $(currentCell).parents('.form-group').next().find('input:visible:enabled:not([readonly]):not([type="hidden"]), select:visible:enabled:not([tabindex="-1"])');

      if (nextCell.length > 0) {
        return nextCell;
      }
    }
  }

  // Cannot click add_new so look for a cell
  lookForNonSiblingCellToFocus(element) {
    let nextCell = this.findNextNonSiblingCell(element);

    if (nextCell.length > 0) {
      nextCell.focus();
    }
  }
}
