import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["field"]

  clearIfZero(event) {
    if(event.target.value === "0.000") {
      this.fieldTargets.forEach((field) => {
        field.value = null
      })
    }
  }

}
